import { createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import configurationService from "../services/configuration.service";
import useCustomTheme from "../utils/theme";

const ConfigurationContext = React.createContext();

const ConfigurationProvider = (props) => {
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const themes = useCustomTheme();
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [logoLight, setLogoLight] = useState("");
  const [logoLightSmall, setLogoLightSmall] = useState("");
  const [logoDark, setLogoDark] = useState("");
  const [logoDarkSmall, setLogoDarkSmall] = useState("");
  const [declination, setDeclination] = useState("");
  const [name, setName] = useState("");
  const [langs, setLangs] = useState([]);
  const [externalUrlToolsMenu, setExternalUrlToolsMenu] = useState("");
  const [countries, setCountries] = useState([]);
  const [sector, setSector] = useState("");
  const [idMatomoTrack, setIdMatomoTrack] = useState();
  const [matomoUrl, setMatomoUrl] = useState();
  const { i18n } = useTranslation();

  const fetchConfiguration = async () => {
    localStorage.removeItem("externalApiUrl");
    return configurationService
      .getThemeConfiguration(i18n.language)
      .then((res) => {
        if (res.isUnderMaintenance) {
          setUnderMaintenance(true);
        }
        setDeclination(res.themeDeclination);
        switch (res.themeDeclination) {
          case "wmenu_wcontent":
            themes.setMenuTheme(createTheme({ palette: res.lightTheme }));
            themes.setTheme(createTheme({ palette: res.lightTheme }));
            break;
          case "wmenu_dcontent":
            themes.setMenuTheme(createTheme({ palette: res.lightTheme }));
            themes.setTheme(createTheme({ palette: res.darkTheme }));
            break;
          case "dmenu_wcontent":
            themes.setMenuTheme(createTheme({ palette: res.darkTheme }));
            themes.setTheme(createTheme({ palette: res.lightTheme }));
            break;
          case "dmenu_dcontent":
            themes.setMenuTheme(createTheme({ palette: res.darkTheme }));
            themes.setTheme(createTheme({ palette: res.darkTheme }));
            break;
          default:
            themes.setTheme(createTheme());
            themes.setMenuTheme(createTheme());
            break;
        }
        setLogoLight(res.logoLight);
        setLogoLightSmall(res.logoLightSmall);
        setLogoDark(res.logoDark);
        setLogoDarkSmall(res.logoDarkSmall);
        setName(res.name);
        setLangs(res.availableLangs);
        setExternalUrlToolsMenu(res.externalUrlToolsMenu);
        setCountries(res.availableShippingCountries);
        setSector(res.sector);
        setIdMatomoTrack(res.idMatomoTrack);
        setMatomoUrl(res.matomoUrl);

        // [#159] Set title and favicon
        document.title = res.title || res.name;
        document.querySelector("#favicon").href = res.favicon;

        let name = res.title || res.name;

        let manifest = {
          short_name: name,
          name: name,
          icons: [
            {
              src: res.favicon,
              sizes: "any",
              type: "image/png",
            },
            {
              src: "favicon.ico",
              sizes: "144x144 48x48",
              type: "image/png",
            },
          ],
          display: "standalone",
          theme_color: "#000000",
          background_color: "#ffffff",
        };
        let blob = new Blob([JSON.stringify(manifest)], {
          type: "application/json",
        });
        let url = URL.createObjectURL(blob);
        document.querySelector("#manifest").setAttribute("href", url);

        if (
          process.env.REACT_APP_ENVIRONMENT !== "development" &&
          !!res.externalApiUrl
        ) {
          localStorage.setItem("externalApiUrl", res.externalApiUrl);
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setApiError(true);
        throw err;
      });
  };

  useEffect(() => {
    // Get configuration values
    setApiError(false);
    fetchConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfigurationContext.Provider
      value={{
        underMaintenance,
        loading,
        apiError,
        themes,
        declination,
        logoLight,
        logoDark,
        logoLightSmall,
        logoDarkSmall,
        name,
        langs,
        externalUrlToolsMenu,
        countries,
        sector,
        idMatomoTrack,
        matomoUrl,
        fetchConfiguration,
      }}
      {...props}
    />
  );
};

const useConfiguration = () => React.useContext(ConfigurationContext);

export { ConfigurationProvider, useConfiguration };
